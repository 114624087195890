import React, { useState } from "react";
import {
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";

const DonorRegistration = (props) => {
  const [state, setState] = useState({});
  const { bankname } = props;
  const router = useHistory();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [bank, setBank] = useState();
  // const [startDate, setStartDate] = useState(new Date());
  const [startDate, setStartDate] = useState(null);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});
    axios
      .post("donor", state)
      .then((res) => {
        setLoading(false);
        // TODO: add success modal
      })
      .catch((e) => {
        setErrors(e.response.data.errors);
        setLoading(false);
      });
  };

  return (
    <Form onSubmit={handleSubmit} style={{ marginLeft: "2em" }}>
      <h3
        style={{ textAlign: "center", marginTop: ".5em", marginBottom: ".5em" }}
      >
        Donor Form
      </h3>

      {/* <Row form> */}
      <Row form>
        <Col md={12}>
          <FormGroup style={{ marginBottom: "0.2rem" }}>
            <Label>Name</Label>
            <Input
              size="sm"
              class="h-20"
              placeholder="Your name"
              name="name"
              style={{ margin: "0px", padding: "0px 0px" }}
              onChange={handleChange}
              invalid={errors.name !== undefined}
            />
            <FormFeedback style={{ color: "whitesmoke" }}>
              {errors.name ? errors.name[0] : null}
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <FormGroup style={{ marginBottom: "0.2rem" }}>
            <Label>Age</Label>
            <Input
              size="sm"
              class="h-20"
              placeholder="Your age"
              name="age"
              invalid={errors.age !== undefined}
              onChange={handleChange}
            />
            <FormFeedback style={{ color: "whitesmoke" }}>
              {errors.age ? errors.age[0] : null}
            </FormFeedback>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup style={{ marginBottom: "0.2rem" }}>
            <Label>Blood Group</Label>
            <Input
              size="sm"
              class="h-20"
              type="select"
              placeholder="Blood Group"
              name="blood"
              onChange={handleChange}
              invalid={errors.blood !== undefined}
            >
              <FormFeedback style={{ color: "whitesmoke" }}>
                {errors.blood ? errors.blood[0] : null}
              </FormFeedback>
              <option size="sm">---</option>
              <option size="sm" value="a+">
                A+
              </option>
              <option size="sm" value="o+">
                O+
              </option>
              <option size="sm" value="B+">
                B+
              </option>
              <option size="sm" value="ab+">
                AB+
              </option>
              <option size="sm" value="a-">
                A-
              </option>
              <option size="sm" value="o-">
                O-
              </option>
              <option size="sm" value="b-">
                B-
              </option>
              <option size="sm" value="ab-">
                AB-
              </option>
            </Input>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup style={{ marginBottom: "0.2rem" }}>
            <Label>Gender</Label>
            <Input
              type="select"
              size="sm"
              class="h-20"
              className="form-control"
              name="gender"
              onChange={handleChange}
              invalid={errors.gender !== undefined}
            >
              <FormFeedback style={{ color: "whitesmoke" }}>
                {errors.gender ? errors.gender[0] : null}
              </FormFeedback>
              <option size="sm">--</option>
              <option size="sm" value="male">
                Male
              </option>
              <option size="sm" value="female">
                Female
              </option>
              <option size="sm" value="other">
                Other
              </option>
            </Input>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup style={{ marginBottom: "0.2rem" }}>
            <Label>Contact</Label>
            <Input
              size="sm"
              class="h-20"
              placeholder="Contact No"
              name="phone"
              onChange={handleChange}
              invalid={errors.phone !== undefined}
            />
            <FormFeedback style={{ color: "whitesmoke" }}>
              {errors.phone ? errors.phone[0] : null}
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={12}>
          <FormGroup style={{ marginBottom: "0.2rem" }}>
            <Label>Email</Label>
            <Input
              size="sm"
              class="h-20"
              placeholder="Your email"
              name="email"
              onChange={handleChange}
              invalid={errors.email !== undefined}
            />
            <FormFeedback style={{ color: "whitesmoke" }}>
              {errors.email ? errors.email[0] : null}
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <FormGroup style={{ marginBottom: "0.2rem" }}>
            <Label>District</Label>
            <Input
              type="select"
              size="sm"
              class="h-20"
              className="form-control"
              name="district"
              onChange={handleChange}
              invalid={errors.district !== undefined}
            >
              <FormFeedback style={{ color: "whitesmoke" }}>
                {errors.district ? errors.district[0] : null}
              </FormFeedback>
              <option>---</option>
              <option value="barguna ">Barguna </option>
              <option value="barisal ">Barisal </option>
              <option value="bhola">Bhola</option>
              <option value="jhalokati">Jhalokati</option>
              <option value="patuakhali">Patuakhali</option>
              <option value="pirojpur"> Pirojpur</option>
              <option value="bandarban">Bandarban</option>
              <option value="brahmanbaria">Brahmanbaria</option>
              <option value="chandpur ">Chandpur </option>
              <option value="chittagong">Chittagong</option>
              <option value="comilla ">Comilla </option>
              <option value="cox's bazar">Cox's Bazar</option>
              <option value=" feni"> Feni</option>
              <option value="khagrachhari ">Khagrachhari </option>
              <option value="lakshmipur ">Lakshmipur </option>
              <option value="noakhali ">Noakhali </option>
              <option value="rangamati ">Rangamati </option>
              <option value="dhaka">Dhaka</option>
              <option value="faridpur ">Faridpur </option>
              <option value="gazipur ">Gazipur </option>
              <option value="gopalganj ">Gopalganj </option>
              <option value="kishoreganj ">Kishoreganj </option>
              <option value="madaripur ">Madaripur </option>
            </Input>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup style={{ marginBottom: "0.2rem" }}>
            <Label>Zone</Label>
            <Input
              size="sm"
              class="h-20"
              placeholder="Your zone"
              name="zone"
              onChange={handleChange}
              invalid={errors.zone !== undefined}
            />
            <FormFeedback style={{ color: "whitesmoke" }}>
              {errors.zone ? errors.zone[0] : null}
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={12}>
          <FormGroup style={{ marginBottom: "0.2rem" }}>
            <Label>Address</Label>
            <Input
              size="sm"
              class="h-20"
              placeholder="Your address"
              type="textarea"
              name="address"
              onChange={handleChange}
              invalid={errors.address !== undefined}
            />
            <FormFeedback style={{ color: "whitesmoke" }}>
              {errors.address ? errors.address[0] : null}
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <FormGroup style={{ marginBottom: "0.2rem" }}>
            <Label for="exampleSelect">Bank Id</Label>
            <Input
              type="select"
              size="sm"
              class="h-20"
              placeholder="Bank Id"
              name="banks_id"
              onChange={handleChange}
              invalid={errors.banks_id !== undefined}
            >
              <option>---</option>
              {bankname ? (
                bankname.map((item) => {
                  // console.log("jahid", bankname.banks_id);
                  return <option value={item.id}>{item.bankname}</option>;
                })
              ) : (
                <option>Jahid</option>
              )}
            </Input>

            <FormFeedback style={{ color: "whitesmoke" }}>
              {errors.banks_id ? errors.banks_id[0] : null}
            </FormFeedback>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup style={{ marginBottom: "0.2rem" }}>
            <Label>Date</Label>
            {/* <Input
              size="sm"
              class="h-20"
              placeholder="Date"
              name="date"
              onChange={handleChange}
              invalid={errors.date !== undefined}
            ></Input> */}

            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              invalid={errors.date !== undefined}
              style={{ innerWidth: "20px" }}
              isClearable
              showDisabledMonthNavigation
              // placeholderText="I have been cleared!"
            />

            <FormFeedback style={{ color: "whitesmoke" }}>
              {errors.date ? errors.date[0] : null}
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={12}>
          <FormGroup style={{ marginBottom: "0.2rem" }}>
            <Label>Password</Label>
            <Input
              size="sm"
              class="h-20"
              placeholder="Password"
              name="password"
              type="password"
              onChange={handleChange}
              invalid={errors.password !== undefined}
            />
            <FormFeedback style={{ color: "whitesmoke" }}>
              {errors.password ? errors.password[0] : null}
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      {console.log("jahid", bankname.banks_id)}
      {/* </Row> */}
      {/* <button onClick={check}>Check</button> */}
      <Button color="success" className="mr-2">
        {loading ? <i className="fa fa-spinner fa-spin fa-fw" /> : "Submit"}
      </Button>
      <Button color="danger" type="reset">
        Cancel
      </Button>
    </Form>
  );
};

export default DonorRegistration;
