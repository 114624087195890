import React from "react";
import { CardFooter } from "reactstrap";

function Footer() {
  return (
    <div style={{ backgroundColor: "black" }}>
      <CardFooter
        style={{ textAlign: "center", marginTop: "5px", color: "whitesmoke" }}
      >
        Footer
      </CardFooter>
    </div>
  );
}

export default Footer;
