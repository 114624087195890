import React from "react";
import { Button, Col } from "reactstrap";
import BankCard from "./BankCard";

const BankList = ({ banks }) => {
  return banks.map((bank) => (
    <Col md={4} key={bank.id}>
      <BankCard {...bank} />
    </Col>
  ));
};

export default BankList;
