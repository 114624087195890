import React from "react";
import { Container } from "reactstrap";
import images from "../images/img1.jpg";
import img2 from "../images/img2.jpg";

const About = () => {
  return (
    <Container>
      {/* <h1>About BloodBank</h1> */}
      <br />
      <div class="row">
        <div class="col">
          <div
            class="card"
            style={{
              backgroundColor: "#8a0303",
              color: "white",
              width: "600px",
            }}
          >
            {/* <div class="card-header">Featured</div> */}
            <div class="card-body">
              <h5 class="card-title">About BloodBank</h5>
              <hr style={{ backgroundColor: "white" }} />
              <p class="card-text">
                Blood donation is a major concern to the society as donated
                blood is lifesaving for individuals who need it. Blood is
                scarce. There is a shortage to active blood donors to meet the
                need of increased blood demand. Blood donation as a therapeutic
                exercise. Globally, approximately 80 million units of blood are
                donated each year. One of the biggest challenges to blood safety
                particularly is accessing safe and adequate quantities of blood
                and blood products. Safe supply of blood and blood components is
                essential, to enable a wide range of critical care procedures to
                be carried out in hospitals. Good knowledge about blood donation
                practices is not transforming in donating blood.
              </p>
              {/* <a href="#" class="btn btn-primary">
                Go somewhere
              </a> */}
            </div>
          </div>
        </div>
        <div class="col">
          <img
            src={images}
            width="450"
            height="315"
            alt="images"
            style={{ borderRadius: "5px" }}
          />
        </div>
      </div>
      <br />

      <h1 class="row justify-content-md-center">About Our Campaning</h1>
      <br />
      {/* <div class="row">
        <div class="col">
          <img src={img2} width="300" alt="images" />
        </div>
        <div class="col">
          At the point when an individual gives blood for someone else who needs
          blood, emerging because of an ailment, it is called blood gift. A
          blood donation is completed on account of a prepared clinical expert
          and in a protected situation, according to the clinical norms. Giving
          blood is a compassionate motion, and more individuals are getting
          attracted to it by each spending year. It is a decent sign as it shows
          that individuals are getting progressively reasonable towards others’
          sufferings. As indicated by the World Health Organization (WHO)
          report, around 117.4 million blood gifts are gathered internationally.
          It also advances the development of new blood vessels.
        </div>
      </div> */}
      <div class="row">
        <div class="col">
          <img
            src={img2}
            width="500"
            height="300"
            alt="images"
            style={{ borderRadius: "5px" }}
          />
        </div>
        <div class="col">
          At the point when an individual gives blood for someone else who needs
          blood, emerging because of an ailment, it is called blood gift. A
          blood donation is completed on account of a prepared clinical expert
          and in a protected situation, according to the clinical norms. Giving
          blood is a compassionate motion, and more individuals are getting
          attracted to it by each spending year. It is a decent sign as it shows
          that individuals are getting progressively reasonable towards others’
          sufferings. As indicated by the World Health Organization (WHO)
          report, around 117.4 million blood gifts are gathered internationally.
          It also advances the development of new blood vessels.
        </div>
      </div>
      <br />
    </Container>
  );
};

export default About;
