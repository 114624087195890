import React from "react";
import { CardBody } from "reactstrap";
import { Card, CardHeader } from "reactstrap";

const SearchResult = ({ datas, setResults }) => {
  return (
    <Card className="mb-4">
      <CardHeader className="d-flex justify-content-between align-items-center">
        <span>Results found ({datas.length})</span>
        <div
          className="text-danger"
          onClick={() => setResults([])}
          style={{ fontSize: 25, cursor: "pointer" }}
        >
          &times;
        </div>
      </CardHeader>
      <CardBody>
        {datas.map((item) => (
          <div key={item.id}>
            <h6>
              <b>Name</b>: {item.name}
            </h6>

            <h6>
              <b>Address</b> : {item.address}
            </h6>
            <h6>
              <b>Phone</b>: {item.phone}
            </h6>

            <hr style={{ height: "1px" }}></hr>
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

export default SearchResult;
