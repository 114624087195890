import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <div>
        <Navbar color="dark" dark inverse={true.toString()} expand="md">
          <NavbarBrand to="/" tag={Link} style={{ color: "red" }}>
            <i class="fa fa-heartbeat" style={{ padding: "3px" }}></i>
            <b>Blood Bank</b>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink
                  to="/create-bank"
                  tag={Link}
                  style={{ color: "whitesmoke" }}
                >
                  <i className="fa fa-plus pr-2"></i>
                  Create Bank
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/about" tag={Link} style={{ color: "whitesmoke" }}>
                  About
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </>
  );
};

export default Header;
