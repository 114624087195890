import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import axios from "axios";
import Pagination from "../components/Pagination";
import DonorRegistration from "../components/DonorRegistration";
import FindbloodForm from "../components/FindbloodForm";
import BankList from "../components/BankList";
import Donorlist from "../components/BankCard";
import SearchResult from "../components/SearchResult";
// import Posts from "../components/Posts";

const Home = () => {
  const [banks, setBanks] = useState([]);
  const [donors, setDonors] = useState([]);
  const [allbank, setAllBank] = useState([]);
  const [data, setData] = useState([]);
  let [page, setPage] = useState(1);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    axios.get(`bank?page=${page}`).then((res) => {
      setBanks(res.data.data);
    });
    axios.get(`bank/all`).then((res) => {
      setDonors(res.data.data);
    });
    axios.get(`allbank`).then((res) => {
      setAllBank(res.data);
    });
  }, [page]);

  const changePage = (type) => {
    // 1 for next page
    // 0 for previous page
    if (type === 1) {
      setPage(++page);
    }

    if (type === 0 && page > 1) {
      setPage(--page);
    }
  };

  return (
    <div style={{ backgroundColor: "#7E7172" }}>
      <Container>
        <Row>
          <Col md={7} style={{ paddingLeft: "50px", paddingRight: "50px" }}>
            <FindbloodForm setResults={setSearchResults} />

            {searchResults.length ? (
              <Row>
                <Col md={12}>
                  <SearchResult
                    datas={searchResults}
                    setResults={setSearchResults}
                  />
                </Col>
              </Row>
            ) : null}

            <Row>
              <BankList banks={banks} />
              <Pagination page={page} changePage={changePage} />
            </Row>
            {/* <Posts posts={posts} loading={loading} /> */}
          </Col>
          <Col
            md={5}
            style={{
              border: "1px solid ",
              padding: "10px",
              boxShadow: "3px 5px 15px red",
              borderRadius: "5px",
              marginTop: "46px",
              backgroundColor: "#8a0303",
              color: "white",
              height: "0%",
            }}
          >
            <DonorRegistration bankname={allbank} donorlists={donors} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;
