import React, { useState, useEffect } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Header from "./components/Header";
import About from "./pages/About";
import CreateBank from "./pages/CreateBank";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import DonarList from "./components/DonarList";

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/create-bank" component={CreateBank} />
        <Route exact path="/donor-list" component={DonarList} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
