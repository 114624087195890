import React from "react";
import { Col } from "reactstrap";

const Pagination = ({ changePage, page }) => {
  return (
    <Col md={12}>
      <nav className="d-flex justify-content-between w-100">
        <button
          className="btn btn-primary btn-sm"
          onClick={() => changePage(0)}
        >
          <i className="fa fa-angle-left"></i> Previous
        </button>

        <span style={{ color: "#FFF" }}>({page})</span>

        <button
          className="btn btn-primary btn-sm"
          onClick={() => changePage(1)}
        >
          Next <i className="fa fa-angle-right"></i>
        </button>
      </nav>
    </Col>
  );
};

export default Pagination;
