import React, { useState } from "react";
import { Form, Input, Label, FormGroup, Button, Row, Col } from "reactstrap";
import axios from "axios";

const FindbloodForm = (props) => {
  const [state, setState] = useState({});

  const { setResults } = props;

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("blood", state)
      .then((res) => {
        setLoading(false);
        setResults(res.data);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      className="shadow p-3 mb-5 bg-white rounded mt-5"
    >
      <h3
        style={{ textAlign: "center", marginTop: ".5em", marginBottom: ".5em" }}
      >
        Find Donor
      </h3>

      <Row form className="flex align-items-center">
        <Col md={4}>
          <FormGroup>
            <Label>Blood</Label>
            <select
              size="sm"
              className="form-control"
              name="blood"
              onChange={handleChange}
            >
              <option size="sm">---</option>
              <option size="sm" value="a+">
                A+
              </option>
              <option size="sm" value="o+">
                O+
              </option>
              <option size="sm" value="B+">
                B+
              </option>
              <option size="sm" value="ab+">
                AB+
              </option>
              <option size="sm" value="a-">
                A-
              </option>
              <option size="sm" value="o-">
                O-
              </option>
              <option size="sm" value="b-">
                B-
              </option>
              <option size="sm" value="ab-">
                AB-
              </option>
            </select>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label>Zone</Label>
            <Input
              placeholder="Your zone"
              name="zone"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>

        <Col md={4} className="mt-3">
          <Button color="success" className="mr-2">
            {loading ? <i className="fa fa-spinner fa-spin fa-fw" /> : "Search"}
          </Button>
          <Button color="danger" type="reset" onClick={() => setResults([])}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FindbloodForm;
