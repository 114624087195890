import React, { useState } from "react";
import {
  Card,
  CardImg,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import DonarList from "./DonarList";
import Findblood from "./Findblood";

const BankCard = ({
  image,
  bankname,
  zone,
  address,
  className,
  buttonLabel,
  phone,
  name,
  props,
  Donorlist,
  datas,
  setResults,
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <Card
        className="mb-4"
        style={{ backgroundColor: "#8a0303", color: "white" }}
      >
        <CardImg src={image} />
        <CardBody>
          <h4>{bankname}</h4>
          <p>{zone}</p>
          <p>{address}</p>
        </CardBody>
        <Button color="danger" onClick={toggle}>
          {buttonLabel} Donar List
        </Button>
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>Donors List</ModalHeader>

          {/* <div
            className="text-danger"
            onClick={() => setResults([])}
            style={{ fontSize: 25, cursor: "pointer" }}
          >
            &times;
          </div> */}

          <div>
            <h6>
              <b>Name</b>: {name}
            </h6>

            <h6>
              <b>Address</b> : {address}
            </h6>

            <hr style={{ height: "1px" }}></hr>
          </div>

          <ModalFooter>
            <Button color="danger" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Card>
    </>
  );
};

export default BankCard;
