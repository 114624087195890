import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap";

import axios from "axios";
import { useHistory } from "react-router-dom";

const CreateBank = () => {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const router = useHistory();

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    axios
      .post("bank", state)
      .then((res) => {
        router.push("/");
        setLoading(false);
      })
      .catch((e) => {
        // console.log(e.response.data);
        setErrors(e.response.data.errors);
        setLoading(false);
      });
  };
  const check = () => {
    console.log(state);
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col
          md={6}
          style={{
            backgroundColor: "darkred",
            border: "1px ",
            boxShadow: "3px 5px 15px red",
            borderRadius: "5px",
            padding: "19px",
            color: "white",
          }}
          className="mx-auto"
        >
          <h3 style={{ textAlign: "center" }}>Create New Bank</h3>

          <Form onSubmit={handleSubmit}>
            <FormGroup style={{ marginBottom: "0rem" }}>
              <Label>Name</Label>
              <Input
                placeholder="Your name"
                name="name"
                onChange={handleChange}
                invalid={errors.name !== undefined}
              />
              <FormFeedback style={{ color: "whitesmoke" }}>
                {errors.name ? errors.name[0] : null}
              </FormFeedback>
            </FormGroup>
            <FormGroup style={{ marginBottom: "0rem" }}>
              <Label>Bank name</Label>
              <Input
                placeholder="Bank name"
                name="bankname"
                onChange={handleChange}
                invalid={errors.bankname !== undefined}
              />
              <FormFeedback style={{ color: "whitesmoke" }}>
                {errors.bankname ? errors.bankname[0] : null}
              </FormFeedback>
            </FormGroup>
            <FormGroup style={{ marginBottom: "0rem" }}>
              <Label>Zone</Label>
              <Input
                placeholder="Your zone"
                name="zone"
                onChange={handleChange}
                invalid={errors.zone !== undefined}
              />
              <FormFeedback style={{ color: "whitesmoke" }}>
                {errors.zone ? errors.zone[0] : null}
              </FormFeedback>
            </FormGroup>
            <FormGroup style={{ marginBottom: "0rem" }}>
              <Label>Division</Label>
              <select
                className="form-control"
                name="division"
                onChange={handleChange}
              >
                <option>--</option>
                <option value="dhaka">Dhaka</option>
                <option value="chattogram">Chattogram</option>
                <option value="barishal">Barishal</option>
                <option value="khulna">Khulna</option>
                <option value="rajshahi">Rajshahi</option>
                <option value="rangpur">Rangpur</option>
                <option value="sylhet">Sylhet</option>
                <option value="mymensingh">Mymensingh</option>
              </select>
            </FormGroup>
            <FormGroup style={{ marginBottom: "0rem" }}>
              <Label>District</Label>
              <select
                className="form-control"
                name="district"
                onChange={handleChange}
              >
                <option>--- </option>
                <option value="barguna ">Barguna </option>
                <option value="barisal ">Barisal </option>
                <option value="bhola">Bhola</option>
                <option value="jhalokati">Jhalokati</option>
                <option value="patuakhali">Patuakhali</option>
                <option value="pirojpur"> Pirojpur</option>
                <option value="bandarban">Bandarban</option>
                <option value="brahmanbaria">Brahmanbaria</option>
                <option value="chandpur ">Chandpur </option>
                <option value="chittagong">Chittagong</option>
                <option value="comilla ">Comilla </option>
                <option value="cox's bazar">Cox's Bazar</option>
                <option value=" feni"> Feni</option>
                <option value="khagrachhari ">Khagrachhari </option>
                <option value="lakshmipur ">Lakshmipur </option>
                <option value="noakhali ">Noakhali </option>
                <option value="rangamati ">Rangamati </option>
                <option value="dhaka">Dhaka</option>
                <option value="faridpur ">Faridpur </option>
                <option value="gazipur ">Gazipur </option>
                <option value="gopalganj ">Gopalganj </option>
                <option value="kishoreganj ">Kishoreganj </option>
                <option value="madaripur ">Madaripur </option>
              </select>
            </FormGroup>
            <FormGroup style={{ marginBottom: "0rem" }}>
              <Label>Address</Label>
              <Input
                placeholder="Your address"
                name="address"
                onChange={handleChange}
                invalid={errors.address !== undefined}
              />
              <FormFeedback style={{ color: "whitesmoke" }}>
                {errors.address ? errors.address[0] : null}
              </FormFeedback>
            </FormGroup>
            <FormGroup style={{ marginBottom: "0rem" }}>
              <Label>Gender</Label>
              <select
                className="form-control"
                name="gender"
                onChange={handleChange}
              >
                <option>--</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </FormGroup>
            <FormGroup style={{ marginBottom: "0rem" }}>
              <Label>Contact</Label>
              <Input
                placeholder="contact No"
                name="contact"
                onChange={handleChange}
                invalid={errors.contact !== undefined}
              />
              <FormFeedback style={{ color: "whitesmoke" }}>
                {errors.contact ? errors.contact[0] : null}
              </FormFeedback>
            </FormGroup>
            <FormGroup style={{ marginBottom: "0rem" }}>
              <Label>Email</Label>
              <Input
                placeholder="Your email"
                name="email"
                onChange={handleChange}
                invalid={errors.email !== undefined}
              />
              <FormFeedback style={{ color: "whitesmoke" }}>
                {errors.email ? errors.email[0] : null}
              </FormFeedback>
            </FormGroup>
            <FormGroup style={{ marginBottom: "0.4rem" }}>
              <Label>Password</Label>
              <Input
                placeholder="Password"
                name="password"
                type="password"
                onChange={handleChange}
                invalid={errors.password !== undefined}
              />
              <FormFeedback style={{ color: "whitesmoke" }}>
                {errors.password ? errors.password[0] : null}
              </FormFeedback>
            </FormGroup>

            <Button color="success" className="mr-2">
              {loading ? (
                <i className="fa fa-spinner fa-spin fa-fw" />
              ) : (
                "Submit"
              )}
            </Button>
            <Button color="danger" type="reset">
              Cancel
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateBank;
